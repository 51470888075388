import { ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { Box, useTokens, type BoxProps } from '@mezzoforte/forge';
import { Icon } from '@mezzoforte/forge-icons';
import { ShadowBox } from '@/components/Box/ShadowBox';
import { UserGreeting } from '@/components/Header/UserGreeting/UserGreeting';
import { NavItemContext } from '@/features/SiteHeader/NavItemContext';

const { colors } = useTokens.huutokaupat;

const ItemGroup = styled(Box)`
  & + & {
    border-top-width: 1px;
    border-top-style: solid;
  }
`;

const SubMenuItem = styled(Box)`
  color: black;
  display: block;

  :has(a:hover) {
    background-color: ${colors.backgroundGray};
  }

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
`;

interface NavItemBase {
  icon: React.ComponentType<React.ComponentProps<Icon>>;
  label: string;
  type: 'Link' | 'Dropdown' | 'DropdownFullwidth' | 'Button';
}

export interface NavItemLink extends NavItemBase {
  type: 'Link';
  href: string;
}

export interface NavItemDropdown extends NavItemBase {
  type: 'Dropdown';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemDropdownFullwidth extends NavItemBase {
  type: 'DropdownFullwidth';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemButton extends NavItemBase {
  type: 'Button';
  onActivate: () => void;
}

export type NavItemProps = NavItemButton | NavItemLink | NavItemDropdown | NavItemDropdownFullwidth;

export interface SubMenuProps {
  items: NavSubItem[][];
}

interface NavSubItemBase {
  label: string;
  type: 'Link' | 'Text' | 'UserGreeting';
}

export interface NavSubItemLink extends NavSubItemBase {
  type: 'Link';
  href: string;
}

export interface NavSubItemText extends NavSubItemBase {
  type: 'Text';
}

export interface NavSubItemUserGreeting extends NavSubItemBase {
  type: 'UserGreeting';
}

export type NavSubItem = NavSubItemLink | NavSubItemText | NavSubItemUserGreeting;

function MenuWrapper(props: BoxProps) {
  return (
    <ShadowBox
      position="absolute"
      top={{ base: 'initial', lg: 76 }}
      bottom={{ base: 'calc(80px + env(safe-area-inset-bottom))', lg: 'initial' }}
      left={{ base: 3, lg: '-24px' }}
      right={{ base: 3, lg: 'initial' }}
      as="ul"
      m={0}
      p={0}
      minWidth={266}
      overflow="auto"
      {...props}
    />
  );
}

function MainNavigationCustomSubMenu(props: BoxProps) {
  return <MenuWrapper {...props} />;
}

function MainNavigationSubMenu({ items, ...props }: SubMenuProps & BoxProps) {
  const { close } = useContext(NavItemContext);
  return (
    <MenuWrapper {...props}>
      {items.map((group) => (
        <ItemGroup
          key={JSON.stringify(group)}
          borderColor="lineBorder"
          py={2}
        >
          {group.map((item) => {
            const text = (
              <Box
                as="span"
                display="block"
                px={3}
                py={2}
              >
                {item.label}
              </Box>
            );

            if (item.type === 'UserGreeting') {
              return <UserGreeting key={item.label} />;
            }

            return (
              <SubMenuItem
                as="li"
                key={item.label}
                fontSize={14}
                fontWeight={500}
                data-test="main-nav-submenu-item"
              >
                {item.type === 'Link' && (
                  <a
                    href={item.href}
                    onClick={close}
                  >
                    {text}
                  </a>
                )}
                {item.type === 'Text' && (
                  <Box
                    as="span"
                    color="subdued"
                  >
                    {text}
                  </Box>
                )}
              </SubMenuItem>
            );
          })}
        </ItemGroup>
      ))}
    </MenuWrapper>
  );
}

export function SubMenu({ display, item }: { display: boolean; item: NavItemDropdown | NavItemDropdownFullwidth }) {
  const positioningProps: BoxProps =
    item.type === 'DropdownFullwidth'
      ? {
          position: { base: 'fixed', lg: 'absolute' },
          top: { base: 3, lg: 93 },
          left: { base: 3, lg: 0 },
          right: { base: 3, lg: 0 },
        }
      : {};

  return (
    <>
      {item.customMenu && (
        <MainNavigationCustomSubMenu
          display={display ? 'block' : 'none'}
          {...positioningProps}
        >
          {item.customMenu}
        </MainNavigationCustomSubMenu>
      )}
      {item.items && (
        <MainNavigationSubMenu
          display={display ? 'block' : 'none'}
          items={item.items}
          maxHeight="calc(100dvh - 90px)"
        />
      )}
    </>
  );
}
