'use client';

import { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@mezzoforte/forge';
import { IconContext } from '@mezzoforte/forge-icons';
import NextLink from '@/components/Link/Link';
import { useClickOutside } from '@/hooks/useClickOutside';
import { NavItemProps, SubMenu } from '@/features/SiteHeader/MainNavigationSubMenu';
import { CompanyIcon } from '@/components/Header/UserGreeting/CompanyIcon';
import { useShouldShowCompanySelector } from '@/hooks/useShouldShowCompanySelector';
import { useCompanySelection } from '@/hooks/useCompanySelection';
import { NavItemContext } from '@/features/SiteHeader/NavItemContext';

const Link = styled(NextLink)`
  text-decoration: none;

  span + span {
    border-bottom: 1px solid transparent;
  }

  &[aria-expanded='true'] span + span {
    border-bottom-color: white;
  }

  color: inherit;
`;

export function NavItem(item: NavItemProps) {
  const { selectedCompany } = useCompanySelection();
  const { shouldShowCompanySelector } = useShouldShowCompanySelector();
  const [isActive, setIsActive] = useState(false);
  const element = useRef<HTMLDivElement & HTMLLIElement>(null);
  const hasSubmenu = item.type === 'Dropdown' || item.type === 'DropdownFullwidth';

  const hideSubmenu = useCallback(() => setIsActive(false), []);
  useClickOutside(element, hideSubmenu);

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (item.type === 'Link') return;
    event.preventDefault();
    if (item.type === 'Button') {
      item.onActivate();
      return;
    }
    setIsActive((val) => !val);
  };

  const navItemContextValue = useMemo(() => ({ close: hideSubmenu }), [hideSubmenu]);
  const iconContextValue = useMemo(() => ({ size: 24 }), []);

  // OK to use Link when no navigation is intended: https://www.w3.org/WAI/tutorials/menus/flyout/#use-parent-as-toggle
  return (
    <NavItemContext.Provider value={navItemContextValue}>
      <Box
        ref={element}
        as="li"
        key={item.label}
        listStyleType="none"
        position={{ base: 'static', lg: item.type === 'DropdownFullwidth' ? 'static' : 'relative' }}
        data-test="main-nav-item"
      >
        <Link
          href={item.type === 'Link' ? item.href : '#'}
          aria-expanded={hasSubmenu ? isActive : undefined}
          onClick={onClick}
          // We have too many non-nextjs pages as Prismic links to justify prefetching
          prefetch={false}
        >
          <Flex
            flexDir="column"
            alignItems="center"
          >
            {item.icon.displayName === 'UserCircle' && selectedCompany && shouldShowCompanySelector ? (
              <CompanyIcon company={selectedCompany} />
            ) : (
              <IconContext.Provider value={iconContextValue}>
                <item.icon />
              </IconContext.Provider>
            )}
            <Box
              as="span"
              fontSize={{ base: 12, lg: 14 }}
              lineHeight={{ base: '19px', lg: '18px' }}
              minW="56px"
              textAlign="center"
            >
              {item.label}
            </Box>
          </Flex>
        </Link>
        {hasSubmenu && (
          <SubMenu
            display={isActive}
            item={item}
          />
        )}
      </Box>
    </NavItemContext.Provider>
  );
}
