import React, { useMemo } from 'react';
import { Box, Button, Flex, Link, Text, useTokens, VisuallyHidden } from '@mezzoforte/forge';
import styled from '@emotion/styled';
import { IconContext, CaretDown, CaretUp, Icon } from '@mezzoforte/forge-icons';

const { breakpoints, colors, fontSizes, fontWeights, lineHeights } = useTokens.huutokaupat;

const MenuItem = styled(Box)`
  @media screen and (min-width: ${breakpoints.lg}) {
    &:last-child {
      border: none;
    }
  }
`;

const LinkItem = styled(Flex)`
  color: ${colors.text};

  :has(a:hover) {
    background-color: ${colors.backgroundGray};
    text-decoration: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const IconButton = styled(Button)`
  :hover {
    background-color: ${colors.backgroundGray};
  }
  svg {
    pointer-events: none;
  }
`;

const AccordionSubItem = styled(Box)`
  color: ${colors.text};

  :hover {
    background-color: ${colors.backgroundGray};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export interface CategoryAccordionItem {
  readonly name: string;
  readonly icon: React.ComponentType<React.ComponentProps<Icon>>;
  readonly href: string;
  readonly children?: CategoryAccordionSubItem[];
}

export interface CategoryAccordionSubItem {
  readonly name: string;
  readonly href: string;
}

interface AccordionItemProps {
  readonly item: CategoryAccordionItem;
  readonly toggleOpen: () => void;
  readonly isOpen: boolean;
}

function AccordionItem({ item, isOpen, toggleOpen, ...props }: AccordionItemProps) {
  const iconContextValue = useMemo(() => ({ size: 16, color: colors.text }), []);

  return (
    <MenuItem
      as="li"
      borderBottom="1px solid"
      borderColor={colors.lineBorder}
      {...props}
    >
      <LinkItem
        alignItems="center"
        width="100%"
        transition="all 0.2s"
        outline={0}
        padding={2}
        background="transparent"
        border={0}
        justifyContent="space-between"
        minHeight="58px"
      >
        <Link
          href={item.href}
          height="100%"
          width="100%"
          fontFamily="heading"
          _hover={{ textDecoration: 'none' }}
        >
          <Flex
            alignItems="center"
            textAlign="left"
          >
            <Box width="50px">
              <item.icon
                size={32}
                weight="light"
              />
            </Box>
            <Text
              fontFamily="button"
              fontSize={fontSizes.button}
              fontWeight={fontWeights.button}
              lineHeight={lineHeights.button}
              dangerouslySetInnerHTML={{ __html: item.name }}
            />
          </Flex>
        </Link>
        {item.children ? (
          <IconButton
            onClick={toggleOpen}
            borderStyle="none"
            p={2}
          >
            <IconContext.Provider value={iconContextValue}>{isOpen ? <CaretUp /> : <CaretDown />}</IconContext.Provider>
            <VisuallyHidden>{isOpen ? 'Piilota' : 'Näytä'} alaosastot</VisuallyHidden>
          </IconButton>
        ) : null}
      </LinkItem>
      {item.children && isOpen ? (
        <Flex
          as="ul"
          listStyleType="none"
          direction="column"
          pl={0}
          pb={3}
          pt={0}
        >
          {item.children.map((child) => (
            <Link
              href={child.href}
              key={child.name}
              fontFamily="heading"
              _hover={{ textDecoration: 'none' }}
            >
              <AccordionSubItem
                as="li"
                key={child.name}
                py={2}
                pl={59}
              >
                <Text
                  fontFamily="button"
                  fontSize={fontSizes.button}
                  fontWeight={fontWeights.button}
                  lineHeight={lineHeights.button}
                  dangerouslySetInnerHTML={{ __html: child.name }}
                />
              </AccordionSubItem>
            </Link>
          ))}
        </Flex>
      ) : null}
    </MenuItem>
  );
}

export default AccordionItem;
